<template>
    <b-modal
      v-model="data.showModal"
      size="sm"
      button-size="sm"
      modal-class="template-modal"
      title-class="w-full"
      footer-class="w-full"
      no-fade
      @show="onShow"
      no-close-on-esc
      hide-footer
      scrollable
    >
      <!-- Modal header -->
      <div slot="modal-title">
        <div class="flex w-full justify-between">
          Select a scale
        </div>
      </div>

      <!-- Modal content -->
      <div class="flex flex-col gap-2">
        <div v-if="isLoading">
          Loading scales..
        </div>

        <div v-for="device in devices" :key="device.id">
          <b-btn
            variant="primary"
            size="sm"
            class="w-100"
            :disabled="device.is_busy || !device.is_online"
            @click="selectDevice(device)"
          >
            <div class="flex gap-1 justify-center items-center">
              <div>{{ device.device_description }}</div>
              <div class="text-xs">
                <span v-if="!device.is_online">(Offline)</span>
                <span v-else-if="device.is_busy">(Busy)</span>
              </div>
            </div>
          </b-btn>
        </div>
      </div>
    </b-modal>
  </template>

<script>
export default {
  name: 'DeviceSelector',
  components: {},
  props: {
    data: Object,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    canSave() {
      return true;
    },
  },
  data() {
    return {
      loadingCount: 0,
      filters: {},
      options: {},
      devices: [],
    };
  },
  methods: {
    fetchDevices() {
      this.loadingCount++;
      this.$http
        .get('/scale/devices')
        .then((res) => {
          this.devices = res.body.devices;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch devices: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    selectDevice(device) {
      this.$emit('select', device);
      this.onClose();
    },
    fetchAll() {
      this.fetchDevices();
    },
    onShow() {
      this.fetchAll();
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
</style>
